import * as React from 'react';
import Layout from '../components/Layout';
import PricingCards from '../components/PricingCards';
import OpinionsFromUsers from '../components/OpinionsFromUsers';
import BannerScheduleMeeting from '../components/BannerScheduleMeeting';
import Header from '../components/Header';
import Footer from '../components/Footer';

export default function PricingPage() {
  return (
    <Layout>
      <Header />
      <PricingCards/>
      <OpinionsFromUsers />
      <BannerScheduleMeeting />
      <Footer />
    </Layout>
  );
}