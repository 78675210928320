import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import "./i18n.js"
import NotFoundPage from './pages/NotFoundPage.js';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import LandingPage from './pages/LandingPage.js';
import MeetingPage from './pages/MeetingPage.js';
import PricingPage from './pages/PricingPage.js';
import SupportPage from './pages/SupportPage.js';
//import ForClubsPage from './pages/ForClubsPage.js';

function App() {
  const { t } = useTranslation();
  return (
    <HelmetProvider>
      <Router>
        <div className="App">
          <Routes>


            {/* NEW */}
            <Route path="" element={<><Helmet><title>{t('Helmet00')}</title></Helmet><LandingPage /></>} />
            <Route path="/meeting" element={<><Helmet><title>{t('Helmet02')}</title></Helmet><MeetingPage /></>} />
            <Route path="/pricing" element={<><Helmet><title>{t('Helmet01')}</title></Helmet><PricingPage /></>} />
            <Route path="/support" element={<><Helmet><title>{t('Helmet04')}</title></Helmet><SupportPage /></>} />

            <Route path="*" element={<><Helmet><title>{t('Helmet03')}</title></Helmet><NotFoundPage /></>} /> {/* Ruta para manejar páginas no encontradas */}



            {/* OLD */}
            {/* <Route path="" element={<><Helmet><title>{t('Helmet00')}</title></Helmet><LandingPage /></>} />
            <Route path="/pricing" element={<><Helmet><title>{t('Helmet01')}</title></Helmet><PricingPage /></>} />
            <Route path="/meeting" element={<><Helmet><title>{t('Helmet02')}</title></Helmet><HubspotMeetingPage /></>} />
            <Route path="/support" element={<><Helmet><title>{t('Helmet04')}</title></Helmet><SupportPage /></>} /> */}
            {/* <Route path="*" element={<><Helmet><title>{t('Helmet03')}</title></Helmet><NotFoundPage /></>} /> Ruta para manejar páginas no encontradas */}

          </Routes>
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;