import * as React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { COLORS, appSignUp } from '../constants/constants';
import { darken, lighten } from '@mui/system';
import { Grid } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import { Grow } from '@mui/material';
import { useTranslation } from 'react-i18next';

function Intro() {
    const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.5 });
    const { t } = useTranslation();
    return (
        <Box sx={{ paddingTop: { xs: 5, md: 7 }, maxWidth: '1250px', margin: 'auto', width: '95%' }}>

            <Grow in={inView} timeout={2000}>
                <Typography
                    ref={ref}
                    sx={{
                        fontFamily: 'var(--font-inter), sans-serif',
                        fontSize: { xs: '40px', md: '60px' },
                        fontWeight: 600,
                        color: 'transparent',
                        background: 'linear-gradient(90deg, #99c2e0,#b3d1ec,#cce0f7,#b3d1ec,#99c2e0)', // Añadido blanco al gradiente
                        backgroundSize: '200% 200%',
                        backgroundClip: 'text',
                        WebkitBackgroundClip: 'text',
                        lineHeight: 1.1,
                    }}
                >
                    {t('Intro00')}
                </Typography>
            </Grow>

            <Box sx={{ maxWidth: '750px', margin: 'auto' }}>
                <Grow in={inView} timeout={2000}>
                    <Typography
                        ref={ref}
                        style={{
                            marginTop: '10px',
                            fontFamily: 'var(--font-inter), sans-serif',
                            fontSize: '22px',
                            color: '#848dac',
                        }}
                    >
                        {t('Intro01')}
                    </Typography>
                </Grow>
            </Box>

            <Grow in={inView} timeout={3000} ref={ref}>
                <Grid container spacing={2} justifyContent="center" sx={{ marginTop: '20px' }}>
                    <Grid item xs={12} sm="auto">
                        <Button variant="contained" fullWidth={true}
                            onClick={() => {
                                window.location.href = appSignUp;
                            }}
                            sx={{
                                maxWidth: '300px',
                                textTransform: 'none',
                                height: '45px',
                                borderRadius: 2,
                                backgroundColor: COLORS.orangeICB, // Color de fondo
                                border: `1px solid ${lighten(COLORS.orangeICB, 0.1)}`, // Borde
                                '&:hover': {
                                    backgroundColor: darken(COLORS.orangeICB, 0.1), // Color de fondo al pasar el ratón
                                }
                            }}
                        >
                            {t('Intro02')}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm="auto">
                        <Button variant="contained" fullWidth={true}
                            onClick={() => {
                                window.location.href = '/meeting';
                            }}
                            sx={{
                                maxWidth: '300px',
                                textTransform: 'none',
                                height: '45px',
                                borderRadius: 2,
                                color: 'lightgray',
                                backgroundColor: '#0c1424', // Color de fondo
                                border: '1px solid #2c3444', // Borde
                                '&:hover': {
                                    backgroundColor: darken('#2c3444', 0.9), // Color de fondo al pasar el ratón
                                }
                            }}
                        >
                            {t('Intro03')}
                        </Button>
                    </Grid>
                </Grid>
            </Grow>

        </Box >
    );
}

export default Intro;
