import React, { useState } from 'react';
import { Box, Typography, Button, Modal } from '@mui/material';
import { COLORS } from '../constants/constants';
import { lighten, darken } from '@mui/system';
import { useInView } from 'react-intersection-observer';
import { Grow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const Section = ({ title, subtitle, description, textForButton, image, video, backgroundImage }) => {
    const [open, setOpen] = useState(false);
    const [showVideo, setShowVideo] = useState(false);
    const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.5 });

    // useEffect(() => {
    //     if (inView) {
    //         const timer = setTimeout(() => {
    //             setShowVideo(true);
    //         }, 1000);
    //         return () => clearTimeout(timer);
    //     }
    // }, [inView]);

    return (
        // <Box sx={{ marginTop: '50px', maxWidth: '800px', margin: 'auto' }}>
        <Box sx={{
            maxWidth: '800px',
            margin: 'auto',
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
        }}>
            <Box sx={{
                height: '1px',
                width: '90%',
                background: `linear-gradient(to right, #000000, ${lighten('#0c1424', 0.2)}, #000000)`,
                margin: 'auto',
                marginTop: 10,
            }} />


            <Grow in={inView} timeout={1000}>
                <div ref={ref}>
                    <Typography sx={{
                        fontSize: '18px',
                        background: `linear-gradient(to right, ${COLORS.orangeICB}, ${lighten(COLORS.orangeICB, 0.2)})`,
                        marginTop: '70px',
                        WebkitBackgroundClip: 'text',
                        color: 'transparent',
                        textAlign: 'center',
                        fontWeight: 600,
                    }}>
                        {title}
                    </Typography>
                </div>
            </Grow>

            <Grow in={inView} timeout={2000}>
                <div ref={ref}>
                    <Typography sx={{
                        fontFamily: 'var(--font-inter), sans-serif',
                        fontSize: { xs: '25px', md: '40px' },
                        fontWeight: 600,
                        color: 'transparent',
                        background: 'linear-gradient(90deg, #99c2e0,#b3d1ec,#cce0f7,#b3d1ec,#99c2e0)',
                        backgroundSize: '200% 200%',
                        WebkitBackgroundClip: 'text',
                        lineHeight: 1.1,
                        marginTop: '15px',
                    }}>
                        {subtitle}
                    </Typography>
                </div>
            </Grow>

            <Grow in={inView} timeout={3000}>
                <div ref={ref}>
                    <Typography sx={{
                        marginTop: '20px',
                        fontFamily: 'var(--font-inter), sans-serif',
                        fontSize: '18px',
                        color: '#848dac',
                    }}>
                        {description}
                    </Typography>
                </div>
            </Grow>



            <Box sx={{ marginTop: '20px' }}>
    <video 
        style={{ 
            borderRadius: 4, 
            width: '100%', // Cambiado de '90%' a '100%' para que se ajuste al contenedor
            maxWidth: '800px', // Asegura que no exceda el ancho máximo deseado
            height: 'auto' // Mantiene la proporción del video
        }} 
        autoPlay 
        muted 
        loop
    >
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
    </video>
</Box>

            {/* <Button
                    variant="contained"
                    sx={{
                        height: '33px',
                        textTransform: 'none',
                        borderRadius: 2,
                        marginTop: '20px',
                        backgroundColor: COLORS.blueICB,
                        border: `1px solid ${lighten(COLORS.blueICB, 0.1)}`,
                        '&:hover': {
                            backgroundColor: darken(COLORS.blueICB, 0.1),
                        }
                    }}
                    onClick={() => setShowVideo(true)}
                >
                    {textForButton}
                </Button> */}

        </Box >
    );
};

const Features = () => {
    const { t } = useTranslation();
    const sections = [
        {
            title: t('Features00'),
            subtitle: t('Features01'),
            description: t('Features02'),
            image: "/images/PlanPractices.png",
            textForButton: t('Features03'),
            video: "/videos/PlanPractices_en.mp4",
            backgroundImage: "/images/secondary-illustration.svg"
        },
        {
            title: t('Features04'),
            subtitle: t('Features05'),
            description: t('Features06'),
            image: "/images/AttendanceTracking.png",
            textForButton: t('Features07'),
            video: "/videos/AttendanceTracking_en.mp4",
            backgroundImage: "/images/page-illustration.svg"
        },
        {
            title: t('Features08'),
            subtitle: t('Features09'),
            description: t('Features10'),
            image: "/images/CreateDrills.png",
            textForButton: t('Features11'),
            video: "/videos/CreateDrills_en.mp4",
            backgroundImage: "/images/secondary-illustration.svg"
        },
        // {
        //     title: "Section 4",
        //     subtitle: "Description for section 4",
        //     description: "Description for section 4",
        //     image: "/NEWimages/section4.png",
        //     textForButton: "Watch Video",
        //     video: "/NEWvideos/section4.mp4",
        //     backgroundImage: "/NEWimages/secondary-illustration.svg"
        // }
    ];

    return (
        <Box>
            {sections.map((section, index) => (
                <Section
                    key={index}
                    backgroundImage={section.backgroundImage}
                    title={section.title}
                    subtitle={section.subtitle}
                    description={section.description}
                    image={section.image}
                    video={section.video}
                    textForButton={section.textForButton}
                />
            ))}
        </Box>
        // </Box>
    );
};

export default Features;