import * as React from 'react';
import NEWheader from '../components/Header';
import NEWLayout from '../components/Layout';
import NEWintro from '../components/Intro';
import NEWModalVideoPicture from '../components/ModalVideoPicture';
import NEWFooter from '../components/Footer';
import NEWOpinionsFromUsers from '../components/OpinionsFromUsers';
import NEWCarousel from '../components/Carousel';
import NEWBannerScheduleMeeting from '../components/BannerScheduleMeeting';
import NEWinstallApp from '../components/InstallApp';
import NEWFeatures from '../components/Features';

export default function LandingPage() {
    return (
        <>
            <NEWLayout>
                <NEWheader />
                <NEWintro />
                <NEWModalVideoPicture />
                <NEWFeatures />
                <NEWinstallApp />
                <NEWCarousel />
                <NEWOpinionsFromUsers />
                <NEWBannerScheduleMeeting />
                <NEWFooter />
            </NEWLayout>
        </>
    );
}
