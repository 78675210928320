import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box, Grow } from '@mui/material';
import { useInView } from 'react-intersection-observer';


export default function IframeMeeting() {
  const { t } = useTranslation();

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5
  });

  return (
    <Grid sx={{ width: '95%', maxWidth: '1000px', margin: 'auto' }}>
      <Grow in={inView} style={{ transformOrigin: '0 0 0' }} timeout={2000}>
        <Grid container justifyContent="center" ref={ref}>
          <Typography sx={{
            fontFamily: 'var(--font-inter), sans-serif',
            fontSize: { xs: '25px', md: '40px' },
            fontWeight: 600,
            color: 'transparent',
            background: 'linear-gradient(90deg, #99c2e0,#b3d1ec,#cce0f7,#b3d1ec,#99c2e0)',
            backgroundSize: '200% 200%',
            WebkitBackgroundClip: 'text',
            lineHeight: 1.1,
            marginTop: '70px',
          }}>
            {t('IframeHubspotMeeting00')}
          </Typography>


        </Grid>
      </Grow>

      <Grow in={inView} style={{ transformOrigin: '0 0 0' }} timeout={2500}>
        <Grid container justifyContent="center" ref={ref}>
          <Typography sx={{
            marginTop: '20px',
            fontFamily: 'var(--font-inter), sans-serif',
            fontSize: '18px',
            color: '#848dac',
          }}>
            {t('IframeHubspotMeeting01')}
          </Typography>


        </Grid>

      </Grow>

      <Box mt={7}>
        <iframe
          src="https://meetings-eu1.hubspot.com/gabriel-roman-bennassar?embed=true"
          style={{ width: '100%', height: '700px', border: '0' }}
          title={t('IframeHubspotMeeting02')} // Título descriptivo para el iframe
        ></iframe>
      </Box>

    </Grid>
  );
}