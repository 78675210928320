import * as React from 'react';
import Layout from '../components/Layout';
import OpinionsFromUsers from '../components/OpinionsFromUsers';
import Header from '../components/Header';
import IframeMeeting from '../components/IframeMeeting';
import Footer from '../components/Footer';
import Carousel from '../components/Carousel';

export default function MeetingPage() {
  return (
    <Layout>
      <Header />
      <IframeMeeting />
      <Carousel />
      <OpinionsFromUsers />
      <Footer />
    </Layout>
  );
}
